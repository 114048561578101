(function ($) {

  // Slide menu

  $("#alloyMenuToggle-container").click(function () {
    $('.alloy-slide-nav').toggleClass('show');
    $(this).toggleClass('show');
    $('.sub-menu').removeClass('show');
  });

  $(".alloy-slide-nav .menu-item-has-children").each(function (index) {
    $(this).children('a').on("click", function (event) {
      event.preventDefault();
      $(this).siblings('.sub-menu').addClass('show');
    });
  });

})(jQuery); // Fully reference jQuery after this point.
