(function ($) {
  // External tools and plugis JS

  // // Settings at https://owlcarousel2.github.io/OwlCarousel2/docs/api-options.html
  // $(document).ready(function () {
  //   $(".owl-carousel.alloy-slider").owlCarousel({
  //     autoHeight: true,
  //     nav: true,
  //     items: 1,
  //     loop: true,
  //   });
  // });

  // // Settings at http://veno.es/venobox/
  // $(document).ready(function () {
  //   $('.venobox').venobox();
  // });
})(jQuery); // Fully reference jQuery after this point.
